import { PaletteMode, ThemeProvider, createTheme } from '@mui/material';
import AppModeProvider from 'providers/AppModeProvider';
import UserStateProvider from 'providers/UserStateProvider';
import { PropsWithChildren, useState } from 'react';

function matchPrefersColorSchemeDark(): MediaQueryList {
  return window.matchMedia?.('(prefers-color-scheme: dark)');
}

export default function Providers({ children }: PropsWithChildren) {
  const [paletteMode, setPaletteMode] = useState<PaletteMode>(
    matchPrefersColorSchemeDark()?.matches ? 'dark' : 'light',
  );

  // Watch for changes
  matchPrefersColorSchemeDark()?.addEventListener('change', (e) => {
    setPaletteMode(e.matches ? 'dark' : 'light');
  });

  const theme = createTheme({ palette: { mode: paletteMode } });

  return (
    <ThemeProvider theme={theme}>
      <UserStateProvider>
        <AppModeProvider>{children}</AppModeProvider>
      </UserStateProvider>
    </ThemeProvider>
  );
}
