import { Button, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import GoodPartOfDayTitle from 'components/GoodPartOfDayTitle';
import { useUserState } from 'providers/UserStateProvider';
import { range } from 'utils/arrayUtils';

const RECENTLY_COMPLETED_DURATION = 60 * 60 * 1000; // 1 hour

export default function HomeModeContainer() {
  const { userState, updateUserState } = useUserState();

  const { testCompletedTime, success, maxNumberOfWords } = userState;

  const millisecondsSinceTestCompletedTime = new Date().getTime() - testCompletedTime;
  const isRecentlyCompleted = millisecondsSinceTestCompletedTime < RECENTLY_COMPLETED_DURATION;

  const handleStartChallenge = (numberOfWords: number) => {
    updateUserState({ ...userState, numberOfWords, challengeStartTime: new Date().getTime() });
  };

  return (
    <>
      {isRecentlyCompleted ? (
        <Typography variant="h6">
          {success ? 'Congrats!' : 'Not to worry, let’s try again.'}
        </Typography>
      ) : (
        <GoodPartOfDayTitle />
      )}

      <Typography mb={2}>How many words do you want to try for your next challenge?</Typography>
      <Grid2 container spacing={2}>
        {range(maxNumberOfWords, 1).map((numberOfWords) => (
          <Grid2 key={numberOfWords} xs={3}>
            <Button
              variant={numberOfWords === maxNumberOfWords ? 'contained' : 'outlined'}
              onClick={() => handleStartChallenge(numberOfWords)}
            >
              {numberOfWords}
            </Button>
          </Grid2>
        ))}
      </Grid2>
    </>
  );
}
