import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import { Button, Chip, Stack, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { WORDS } from 'data/WORDS';
import { ChangeEvent, FormEvent, useState } from 'react';

interface IProps {
  startIndex: number;
  numberOfWords: number;
  initialAnswers?: Array<string>;
  onAnswerSubmitted?: (answer: string) => void;
  onComplete: (success: boolean) => void;
}

type Answer = { word: string; isCorrect: boolean };

export default function Test(props: IProps) {
  const { startIndex, numberOfWords, initialAnswers, onAnswerSubmitted, onComplete } = props;

  const expectedWords = WORDS.slice(startIndex, startIndex + numberOfWords);

  const [answers, setAnswers] = useState<Array<Answer>>(
    initialAnswers?.map((word) => ({ word, isCorrect: expectedWords.includes(word) })) ?? [],
  );

  const isComplete =
    answers.filter((answer) => answer.isCorrect).length === numberOfWords ||
    answers.filter((answer) => !answer.isCorrect).length === 3;

  const [currentAnswer, setCurrentAnswer] = useState('');

  const handleChangeWord = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentAnswer(e.target.value.toLocaleLowerCase().trim());
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    if (currentAnswer && !answers.find((answer) => answer.word === currentAnswer)) {
      const isCorrect = expectedWords.includes(currentAnswer);
      setAnswers((w) => w.concat({ word: currentAnswer, isCorrect }));
      setCurrentAnswer('');
      onAnswerSubmitted?.(currentAnswer);
    }
    e.preventDefault();
    return false;
  };

  const handleClose = () => {
    if (answers.filter((answer) => answer.isCorrect).length === numberOfWords) {
      onComplete(true);
    }
    if (answers.filter((answer) => !answer.isCorrect).length === 3) {
      onComplete(false);
    }
  };

  const correctAnswers = answers.filter((answer) => answer.isCorrect).map((answer) => answer.word);

  const missedWords = isComplete
    ? expectedWords.filter((word) => !correctAnswers.includes(word))
    : [];

  return (
    <Stack direction="column" spacing={2}>
      {!isComplete ? (
        <>
          <Typography>Please enter your words one at a time.</Typography>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Stack direction="row" spacing={2} alignItems="center">
              <TextField
                label="Enter a word"
                autoCapitalize="off"
                autoFocus
                fullWidth
                variant="outlined"
                onChange={handleChangeWord}
                value={currentAnswer}
              />
              <Button variant="contained" type="submit">
                Submit
              </Button>
            </Stack>
          </form>
        </>
      ) : (
        <Typography>Your answers were:</Typography>
      )}
      {answers.length > 0 && (
        <Grid container spacing={1}>
          {answers.map((answer) => (
            <Grid key={answer.word}>
              <Chip
                icon={answer.isCorrect ? <DoneIcon /> : <ClearIcon />}
                label={answer.word}
                color={answer.isCorrect ? 'success' : 'error'}
                variant="filled"
              />
            </Grid>
          ))}
        </Grid>
      )}
      <Stack direction="row" spacing={2} justifyContent="end">
        <Chip
          icon={<DoneIcon />}
          label={`${correctAnswers.length}/${numberOfWords}`}
          color="success"
          variant="outlined"
        />
        <Chip
          icon={<ClearIcon />}
          label={`${answers.length - correctAnswers.length}/3`}
          color="error"
          variant="outlined"
        />
      </Stack>
      {isComplete && (
        <>
          {missedWords.length > 0 ? (
            <>
              <Typography>Bad luck! You missed the following words:</Typography>
              <Grid container spacing={1}>
                {missedWords.map((word) => (
                  <Grid key={word}>
                    <Chip label={word} color="success" variant="filled" />
                  </Grid>
                ))}
              </Grid>
              <Button variant="contained" onClick={handleClose}>
                Ok
              </Button>
            </>
          ) : (
            <>
              <Typography>Nice! You remembered them all.</Typography>
              <Button variant="contained" onClick={handleClose}>
                Hooray!
              </Button>
            </>
          )}
        </>
      )}
    </Stack>
  );
}
